<template>
<div class="events list_content_tickets_group">
  <ol class="w-list-unstyled">
    <ticket-detail
      v-for="(ticket, index) in listTickets"
      :key="index"
      :ticket="ticket"
      :index="index"
      :selectedIdx="selectedIdx"
      :defaultNum="numOfPax"
      @change="changeTicket"
    />
  </ol>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PickerTicket',
  components: {
    TicketDetail: () => import('@/components/productPage/TicketDetail'),
  },
  props: {
    numOfPax: {
      type: Number,
      default: 1,
    },
    listTickets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIdx: 0,
      numOfTickets: 0,
    };
  },
  computed: {
    ...mapGetters({
      product: 'GET_PRODUCT',
      device: 'GET_DEVICE',
    }),
  },
  watch: {
  },
  methods: {
    changeTicket(pData) {
      if (pData.numOfTickets !== 0) {
        this.selectedIdx = pData.index;
        this.numOfTickets = pData.numOfTickets;
        this.$emit('change', { index: this.selectedIdx, numOfTickets: this.numOfTickets });
      }
    },
  },
};
</script>

<style>
  .list_content_tickets_group{
    border-radius: 15px;
    padding: 24px 15px;
    margin-top: 0;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
</style>
